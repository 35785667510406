import React from "react";

// Components
import Button from "./button";

// Assets
import EarthIllustration from "../assets/svgs/earth-illustration.svg";

const WhoWeAre = ({ setShowContactModal }) => {
  return (
    <div className="bg-white">
      <div className="bg-[#4C4F52]">
        <div
          className="container-fluid gradient6"
          id="joinus"
        >
          <div className="container-padded pb-0 lg:pb-20 container lg:flex gap-x-10">
            <div className="mx-auto flex flex-col lg:max-w-[678px]">
              <h1 className="header1 text-left text-white">
                Understand the vision <br className="hidden lg:block" /> driving
                our work
              </h1>
              <p className="subtitle1 text-gray-100 mt-16 max-w-[665px]">
                We are driving towards a new data economy that works with
                consumers and for them.
              </p>
              <p className="subtitle1 text-gray-100 mt-4 max-w-[665px]">
                We are building a global consumer data sharing network that
                helps fintechs and merchants prevent fraud and improve payments
                experiences, whilst empowering consumers as the custodians of
                their own data.
              </p>
              <p className="subtitle1 text-gray-100 mt-4 max-w-[665px]">
                Data privacy, sovereignty and security is core to our identity -
                data is only accessible and shared after direct consent by the
                consumer
              </p>
              <Button
                title="Contact us"
                color="secondary"
                className="mt-16"
                onClick={() => setShowContactModal(true)}
              />
            </div>
            <div className="relative flex-1 h-full mt-10 lg:mt-0">
              <EarthIllustration className="max-w-full lg:max-w-none mx-auto lg:mr-0 lg:absolute top-20 bottom-0 left-0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
