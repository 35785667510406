import React, { useState } from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

// Components
import WhoWeAre from "../components/WhoWeAre";

// const socialCardImage = { src: "/social_card.png", height: 600, width: 1200 };

const WhoWeArePage = ({ location }) => {
  const [showContactModal, setShowContactModal] = useState(false);
  return (
    <Layout {...{ location, showContactModal, setShowContactModal }}>
      <WhoWeAre {...{ setShowContactModal }} />
    </Layout>
  );
};

export const Head = () => <Seo title="Trudenty" />;

export default WhoWeArePage;
